.home {
  display: grid;
  grid-template-columns: repeat(2, 0fr);
  gap: 2rem;
  height: fit-content;
  align-items: stretch;
  justify-items: start;
  justify-content: center;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }

  .card {
    min-width: 330px;
    width: 433px;
    max-width: 433px;
    height: fit-content;
    padding: 1rem;
    text-align: center;
    max-height: 245px;
    min-height: 136px;
    &.primary {
      height: 245px;
    }
    &.secondary {
      height: 136px;
    }

    @media (max-width: 440px) {
      padding: 0.5rem;
      &.primary {
        width: 330px;
        height: 230px;
      }
      &.secondary {
        width: 330px;
        height: 136px;
      }
    }
  }
}
