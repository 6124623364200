@use "../sass/colors";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  /* 1rem = tamaño del fuente raiz (por defaul 16px) */
  /* Vamos a llevar los 16px a 10px por una cuestion de practicidad, es decir, si queremos un font-size de 20px serían 2rem  */
  /* Para que no sea 10px y condicionar al usuario a que no pueda ser modificado se hará con porcentaje  */
  /* 10/16 = 0,625 */
  /* 1px = .1rem - 10px = 1rem */
  font-size: 62.5%;

  @media (max-width: 414px) {
    font-size: 56.25%; // 9px base (90% de 62.5%)
  }

  @media (max-width: 390px) {
    font-size: 43.75%; // 7px base
  }
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-VariableFont_slnt\,wght.ttf") format("truetype");
  font-weight: 100 900; // Rango disponibles en la variable font
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  min-height: calc(100vh);
  height: calc(100vh - 80px);
  overflow: auto;
}

//TODO: Adecuar los colores cuando el archivo _colors.scss tenga la paleta de colores
//Esto dará color al texto seleccionado por el usuario para que sea acorde a la paleta de colores
::selection {
  background-color: colors.color("aqua", 100);
  color: color("common", "white");
}

// TODO: Customizar scrollbar - color y tamaño
// TODO: Se debe ajustar a la paleta de colores cuando tengamos el primary en colors.scss
::-webkit-scrollbar {
  width: 0.6rem;
  height: 1rem;
}
::-webkit-scrollbar-track {
  background-color: color("background-main");
}
::-webkit-scrollbar-thumb {
  background-color: colors.color("aqua", 100);
  border-radius: 1rem;

  &:hover {
    //TODO: Es para darle un efecto cuando te posas sobre el scroll, mas adelante definir un color
    // background-color: change-color($color: colors.$color-background-main, $alpha: 0.85);
  }
}
