@use "../../sass/colors" as *;

.card-color-line {
  height: 1.8rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  &.primary {
    background-color: color("aqua", 200);
  }
  &.secondary {
    background-color: #90A4AE;
  }
}
